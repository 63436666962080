<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="home">
    <van-nav-bar title="标题" left-arrow v-if="!isWechat">
      <template #left>
        <van-icon name="arrow-left" size="20" @click="goToUrl()" />
      </template>
      <template #title>
        <h3 class="title">
          {{ title }}
        </h3>
      </template>
    </van-nav-bar>
    <van-row>
      <van-col span="24">
        <van-cell style="padding-bottom:0">
          <template #title>
            <van-image v-if="info.avatar" :src="cdnUrl + '/' + info.avatar" />
          </template>
        </van-cell>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="24">
        <van-cell>
          <template #title>
            <div class="descriptions el-descriptions">
              <div class="el-descriptions__header">
                <div class="el-descriptions__title">
                  <div class="icon">
                    <div class="el-image"><img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAVJJREFUSInllMFxwjAQRf9aOXDDFLCatdMALdABxxyTDtIBdEAJTgehA0oIHdixLzky3KXNxRAbbJDDZIaZ/JOs0f9vLa0E3JMemZ9Ta1//JDy1dpUwa8KsKXMW6qNrC0QkjpzLFJifGNfemJeiKHa/BoiIkPfvUJ12u2mrUTS7BOkF1OEbqMqlIkBU1JAiGBAcHgCJOsKng8IBQFXIuQ8ROdvKFkCY5+TcsPAfxeTcRphbzWAOg7q/MwCjDvMORF8AYgAAUVHPn64dEfAUj8efu/1+e/yD1Nqlqq66yiLgLa+qSV6WiarOVHWWl2WixiQErHs8WWrtsh63lTBr81uNmfS1oYgIOZc35/KqamWeHfJAXbxkQYDI+963J/J+cTNAVReH/WyqPrerD1/QFp1C6vCr1QPAQ8iiBuQ4DvUFA4YGH3RrF90hoO92hugW7z/WN8gLlQOqe6bjAAAAAElFTkSuQmCC"
                        class="el-image__inner">
                      <!---->
                    </div>
                  </div>
                  <div class="text">基本信息</div>
                </div>
                <div class="el-descriptions__extra"></div>
              </div>
              <div class="el-descriptions__body">
                <table class="el-descriptions__table is-bordered el-descriptions--medium">
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        姓名</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content"><span
                          class="color1">{{ info.name }}</span></td>
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        年龄</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">{{
                        info.dob |
                          filterAge
                      }}/{{ info.ayiZodiac }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        身高</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">{{
                        info.height ?
                          info.height + 'cm' : ''
                      }}</td>
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        体重</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">{{
                        info.weight ?
                          info.weight + 'kg' : ''
                      }}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        学历</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">
                        {{ dictFormat('jiazheng_ayi_education', info.ayiEducationId) }}
                      </td>
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        籍贯</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">{{
                        formatArea2(info.ayiCityId)
                      }}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        服务内容</th>
                      <td colspan="3" class="el-descriptions-item__cell el-descriptions-item__content"
                        v-if="info.serviceTypeId">{{ idsFormat(info.serviceTypeId, 'jiazheng_service_type').join(' ') }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        经验</th>
                      <td colspan="3" class="el-descriptions-item__cell el-descriptions-item__content">{{
                        dictFormat('jiazheng_ayi_experience', info.ayiExperienceId)
                      }}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        证书</th>
                      <td colspan="3" class="el-descriptions-item__cell el-descriptions-item__content">
                        {{
                          info.ayiCertificateId ?
                            idsFormat(info.ayiCertificateId, 'jiazheng_ayi_certificate').join(' ') : ''
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </van-cell>
      </van-col>
    </van-row>
    <van-row v-if="isAdmin">
      <van-col span="24">
        <van-cell>
          <template #title>
            <div class="descriptions el-descriptions">
              <div class="el-descriptions__header">
                <div class="el-descriptions__title">
                  <div class="icon">
                    <div class="el-image"><img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAUNJREFUSInVkjtSwzAQQN86Tu9QUWqcUMMRSEnJCeAGDCcgnASO4iMknWdwPCqpiLmARBOBnTiy7GQyw1Zr7fo9fRb+e0ho4yxNFwIPVmSJtVpEVgaq8Xi8zPNcHyWYpekCePG0VALVVr4qynIRLAiA78mKspy4j+jEcIBEKZV0CgbCAYjj+MYr6IILaKJobkXuEcn26taqX1lfOIAReV4XRQaglMriKNrU67YmaJwg9FpEpHK51roCqp2G6z1Brzs35sk95Pa/pFGvnUAArqbTR2vtWxD8L9yuk7aaG1XvmHZEcgAOtVGNAD7W63fg9QhZIwS0y0cu+dpssovJRIDbY+EjY+aF1p8NwSkkDp5rrd3aaLdpqKQN3ioYIjkEPyjoI/HBvYIQSRe8U+CThMCDBG2SUHiwoCb5Bi5jY+5C4GeJH5oXrt3ycYrZAAAAAElFTkSuQmCC"
                        class="el-image__inner">
                      <!---->
                    </div>
                  </div>
                  <div class="text">其它信息</div>
                </div>
                <div class="el-descriptions__extra"></div>
              </div>
              <div class="el-descriptions__body">
                <table class="el-descriptions__table is-bordered el-descriptions--medium">
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        编号</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content"><span
                          data-v-4ceb7e64="" class="color3">{{ (area == 1 ? 'H' : 'A') + info.ayiId }}</span></td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        是否住家</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content"><span
                          data-v-4ceb7e64="" class="color1">{{ info.isLive == 1 ? "住家" : "不住家" }} </span></td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        工作状态</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content"><span
                          data-v-4ceb7e64="" class="color1">{{
                            info.workStatus ? idsFormat(info.workStatus,
                              'jiazheng_workstatus').join(' ') : ""
                          }}</span></td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        居住城市</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">
                        <div v-if="info.areaCountryId == 1">
                          <span class="color1">{{ info.ayiLiveCityId | filterArea3(that) }} - {{
                            info.ayiLiveProvinceId
                              |
                              filterArea3(that)
                          }}</span>
                        </div>
                        <div v-else>
                          <span class="color1"> {{ info.ayiLiveCityId | filterArea3(that) }} - {{
                            info.ayiLiveProvinceId
                              |
                              filterArea3(that)
                          }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="info.cityIds">
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        工作城市</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">
                        <span class="color1" style="margin-left:2px" v-for="item in info.cityIds.split(',')"
                          :key="item"> {{ formatArea(item) }}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="info.ayiVisaId">
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        签证</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">
                        <span class="color1">{{
                          info.ayiVisaId ? idsFormat(info.ayiVisaId,
                            'jiazheng_ayi_visa').join(' ') : ""
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="info.ayiTagId">
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        标签</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">
                        <span class="color1">{{
                          info.ayiVisaId ? idsFormat(info.ayiTagId,
                            'jiazheng_ayi_tag').join(' ') : ""
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        联系地址</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">
                        {{ info.address }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        手机号</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">
                        <div data-v-4ceb7e64=""
                          style="display: flex; justify-content: space-between; align-items: center;"><span
                            data-v-4ceb7e64="">{{ info.mobile }}</span>
                          <button data-v-4ceb7e64="" type="button" v-if="info.mobile"
                            class="el-button copy el-button--primary el-button--mini" 
                            @click="onCopy(info.mobile)"><span>复制手机号</span></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="el-descriptions-row">
                      <th colspan="1" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">
                        微信</th>
                      <td colspan="1" class="el-descriptions-item__cell el-descriptions-item__content">
                        <div data-v-4ceb7e64=""
                          style="display: flex; justify-content: space-between; align-items: center;"><span
                            data-v-4ceb7e64="">{{ info.wechat }}</span>
                          <button data-v-4ceb7e64="" type="button" v-if="info.wechat"
                            class="el-button copy el-button--primary el-button--mini"
                            @click="onCopy(info.wechat)"><span>复制微信号</span></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </van-cell>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="24">
        <van-cell>
          <div class="descriptions workExperienceLabel el-descriptions">
            <div class="el-descriptions__header">
              <div class="el-descriptions__title">
                <div class="icon">
                  <div class="el-image"><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAY5JREFUSIntlc9RwlAQxr8NCh5wJsdNOPisQDoQOrAE6EArACqADsQKgAqIHWAHOUhmjzhyyODIeiBRBvPniUf9Tslm9/u9ZPe9AH9KxhjXGOPm3WeJbI03cTwE0AEAIrpTVRdALzEJliLtowGe592S6rBkpd2lyPgw7tgAHOCiNInoKqfWQqpPpf6qs6x4xcb/db1enNfrBKCVkzLI+jzWgAQS5EAGkUi/sNj3vInPrD6zNpjnhbnM/TTXZy42RjJFPrPuByMRajDPFXisnp2NwjBcHUKSvOMBnzGiFQFjVX0BADjOolqtBofQ4wGZVbQCUTeKomkZ4MRmFd+k6kJ1wsyXzm43d1IwqS4IGDyLBIDtPshRBbh3gId9sAKtLTBP+3TcG6R+RM3TWm2xieOsx70Gc2gFiEQKzyyfOSscvgOBFaCw4dkKt0BbRMJf9SBH49QcSJtM9DVuyTURjUBkNevA7p/gAO1IpJuaJ/F8GWPctzi+AXCtRE0ABqpuAg5JdbUlmqnqdN/0Xz/SB6/gqUIXKaE0AAAAAElFTkSuQmCC"
                      class="el-image__inner">
                    <!---->
                  </div>
                </div>
                <div class="text">工作简历</div>
              </div>
              <div class="el-descriptions__extra"></div>
            </div>
            <div class="el-descriptions__body">
              <table class="el-descriptions__table is-bordered el-descriptions--medium">
                <tbody>
                  <tr class="el-descriptions-row">
                    <th colspan="2" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">姓名
                    </th>
                  </tr>
                  <tr class="el-descriptions-row">
                    <td colspan="2" class="el-descriptions-item__cell el-descriptions-item__content">
                      <div class="workExperience" v-html="info.workExperience">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </van-cell>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="24">
        <van-cell>
          <div class="descriptions workExperienceLabel el-descriptions">
            <div class="el-descriptions__header">
              <div class="el-descriptions__title">
                <div class="icon">
                  <div class="el-image"><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAeBJREFUSIntlMFt20AURN9fkoDhXFgCA1L23qJUYKmC2BXY7iCpQEkH6iBSBbErkFJBlBsjiQBL4CVwANL8OUhyFJFL24BPQeay3Nn9M5zl58J/PAI5JHpxfGVELlW1DxTAQuF2lWWTlnWAuYh8/bFejzsNrLWRluVnhYFj41xFQv4IN9YlCK7TNM1bDU7jeOYSfwbyZZa93icMwGmSvH8BcYCoF8dXDQNVPXsB8Y2gyOX+3N+OgyfWT4Db4/v7+a+jo5CqihTeqWpoRKb4fn5Y4DckHBCRDwedUgA5MO+q87fFC1UduDapyHi5Xo/7URT+9P2RqJ4DocBCguAaoC7LEXAOFIhMj6tqvMjzwgeoVafiPqbiVVV9ArjzvC+y9yIKAy3L2XYabccQ1dGd550Bw4c2PUmSb44ev1lm2YVNkkGtOmtZ78KF2T0Z379gc6Z/Q+Q7gNZ16w/WCZH+g0GaprkJgmHDRPUNgIo0zR+BquZmn3CY9AGWWXbTMO+CyGKVZRNzyLeYRCdJ8hGgNWE78u2RN2/THay1Ub3pkAhAjXm7Wq0Wh3yreBAMd5deI4EridT1rNfr9Z3fqkW8M4EjSaHGDB1JGuKdCRxJCs/ziha+VfxZsNZG1troqfy/g9/QJ+tCwCyoMgAAAABJRU5ErkJggg=="
                      class="el-image__inner">
                    <!---->
                  </div>
                </div>
                <div class="text">阿姨评价</div>
              </div>
              <div class="el-descriptions__extra"></div>
            </div>
            <div class="el-descriptions__body">
              <table class="el-descriptions__table is-bordered el-descriptions--medium">
                <tbody>
                  <tr class="el-descriptions-row">
                    <th colspan="2" class="el-descriptions-item__cell el-descriptions-item__label is-bordered-label ">姓名
                    </th>
                  </tr>
                  <tr class="el-descriptions-row">
                    <td colspan="2" class="el-descriptions-item__cell el-descriptions-item__content">
                      <div class="workExperience" v-html="info.description"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </van-cell>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="24">
        <van-cell>
          <template #title>
            <!-- <h4>才艺图片</h4> -->
            <div class="imgs" v-if="info.caiyiImgs">
              <van-image :key="item" v-for="item in caiyiImgsArr" fit="cover" lazy-load :src="item"
                @click="setData('caiyiShow', true); showImgArr = [item];" />
            </div>
          </template>
        </van-cell>
        <van-image-preview v-model="caiyiShow" :images="showImgArr">
          <!-- <template v-slot:index>第{{ index }}页</template> -->
        </van-image-preview>

        <van-cell>
          <template #title>
            <!-- <h4>技能图片</h4> -->
            <div class="imgs" v-if="info.skillImgs">
              <van-image :key="item" v-for="item in skillImgsArr" fit="cover" lazy-load :src="item"
                @click="setData('skillShow', true); showImgArr = [item];" />
            </div>
          </template>
        </van-cell>
        <van-image-preview v-model="skillShow" :images="showImgArr">
          <!-- <template v-slot:index>第{{ index }}页</template> -->
        </van-image-preview>
        <van-cell>
          <template #title>
            <video width="100%" v-if="info.video" :src="cdnUrl + '/' + info.video" controls="controls" />
          </template>
        </van-cell>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import jiazheng from "@/mixins/jiazheng";
import { Toast } from 'vant';
import homeIcon from '@/assets/image/home-heart-fill.png'
import handIcon from '@/assets/image/hand-heart-fill.png'
import heartsIcon from '@/assets/image/hearts-fill.png'
import priceIcon from '@/assets/image/price-tag-3-fill.png'
export default {
  mixins: [jiazheng],
  data: function () {
    return {
      icon: {
        homeIcon,
        handIcon,
        heartsIcon,
        priceIcon,
      },
      pathUrl: '',
      title: "",
      isAdmin: false,
      loading: false,
      caiyiShow: false,
      skillShow: false,
      refreshing: false,
      finished: false,
      caiyiImgsArr: [],
      skillImgsArr: [],
      showImgArr: [],
      info: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(to)
      console.log(from)
      vm.pathUrl = from.path;
    })
  },
  watch: {

  },
  mounted() {
    let isAdmin = localStorage.getItem('isAdmin')
    if (isAdmin) {
      this.isAdmin = true;
    }
    this.getAllDicts().then(() => {
      this.load();
    });
  },
  methods: {
    goToUrl() {
      console.log(this.pathUrl);
      if (this.pathUrl == '/') {
        this.$router.push({ path: "/index", query: { activeCityIndex: this.$route.query.activeCityIndex, activeSeviceTypeIndex: this.$route.query.activeSeviceTypeIndex, area: this.area } })
      } else {
        this.$router.back()
      }
      // localStorage.getItem('fromPath')
      // if (this.$route.query.activeCityIndex!=undefined || this.$route.query.activeSeviceTypeIndex!=undefined) {
      //   this.$router.back()
      // }else{
      //   this.$router.push({ path: "/index", query: { activeCityIndex: this.$route.query.activeCityIndex, activeSeviceTypeIndex: this.$route.query.activeSeviceTypeIndex, area: this.area } })
      // }
    },
    setData(k, v) {
      console.log(k, v);
      this[k] = v;
    },
    onCopy(info) {
      // eslint-disable-next-line no-undef
      let clipboard = new this.clipboard('.copy', {
        text: function () {
          return info;
        }
      });
      clipboard.on('success', () => {
        Toast('复制成功');
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        // 释放内存
        Toast('复制失败');
        clipboard.destroy()
      })
    },
    load: function () {
      this.request({
        url: this.apiUrl + '/ayi/get',
        method: "get",
        params: {
          uuid: this.$route.query.uuid,
        },
      }).then(res => {
        this.caiyiImgsArr = res.data.caiyiImgs.split(',').map(v => {
          return this.cdnUrl + '/' + v
        })
        this.skillImgsArr = res.data.skillImgs.split(',').map(v => {
          return this.cdnUrl + '/' + v
        })
        this.info = res.data
        document.title = this.title = (this.area == 1 ? 'H' : 'A') + res.data.ayiId + '-' + res.data.name

        console.log(res)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.color1 {
  font-weight: bold;
  cursor: pointer;
  color: #1890ff;
}

.color2 {
  font-weight: bold;
  cursor: pointer;
  color: #67c23a;
}

.color3 {
  font-weight: bold;
  cursor: pointer;
  color: red;
}

.home {
  padding-bottom: 20px;
}

/deep/ {
  .van-cell {
    padding: 10px 16px 5px;
  }

  .el-descriptions__header {
    margin-bottom: 0px;
  }

  .el-descriptions__title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #A7A7A7;
    border-bottom: none;
    background-color: #EEB1B1;
    height: 42px;
    margin-bottom: 0;

    .icon {
      margin-left: 8px;
    }

    .text {
      margin-left: 2px;
    }

    .el-image {
      width: 20px;
      margin-top: 8px;
    }
  }

  .el-descriptions {
    .el-descriptions-item__label {
      color: #605C5C;
      font-weight: bold;
      font-size: 15px;
    }
  }

  .workExperienceLabel {
    .el-descriptions-item__label {
      display: none;
    }
  }

  .el-descriptions__table {
    border: 1px solid #A7A7A7;
  }

  .el-descriptions .is-bordered .el-descriptions-item__cell {
    border: 1px solid #A7A7A7;
  }

  .workExperience {
    color: #969799;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 15px;
      color: #646566;
    }
  }
}

.imgs {

  // display: flex;
  .van-image {
    // width:calc(50% - 20px);
    // &:nth-child(2n){
    //   margin-left:20px;
    // }
  }
}
</style>